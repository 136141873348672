import round from 'lodash/round';

function calculateRowItem(row_old, currency_type_id_new, exchange_rate_sale) {

    let currency_type_id_old = row_old.item.currency_type_id
    let unit_price = parseFloat(row_old.item.unit_price)

    if (currency_type_id_old === 'PEN' && currency_type_id_old !== currency_type_id_new)
    {
        unit_price = unit_price / exchange_rate_sale;
    }

    if (currency_type_id_new === 'PEN' && currency_type_id_old !== currency_type_id_new)
    {
        unit_price = unit_price * exchange_rate_sale;
    }

    let row = {
        id: row_old.id,
        item_id: row_old.item.id,
        item: row_old.item,
        currency_type_id: currency_type_id_new,
        quantity: row_old.quantity,
        static_price: row_old.static_price,
        unit_value: 0,
        affectation_igv_type_id: row_old.affectation_igv_type_id,
        affectation_igv_type: row_old.affectation_igv_type,
        total_base_igv: 0,
        percentage_igv: 10,
        total_igv: 0,
        system_isc_type_id: null,
        total_base_isc: 0,
        percentage_isc: 0,
        total_isc: 0,
        total_base_other_taxes: 0,
        percentage_other_taxes: 0,
        total_other_taxes: 0,
        total_plastic_bag_taxes: 0,
        total_taxes: 0,
        item_unit_type_id: row_old.item_unit_type_id,
        price_type_id: '01',
        unit_price: unit_price,
        total_value: 0,
        total_discount: 0,
        total_charge: 0,
        total: 0,
        printed: (row_old.printed) ? row_old.printed : false,
        note: (row_old.note) ? row_old.note : null,
        attributes: row_old.attributes,
        charges: row_old.charges,
        discount: row_old.discount
    };

    let percentage_igv = 10
    let unit_value = row.unit_price
    
    // descomentar para op. gratuitas
    // if (row.affectation_igv_type.free) {
    //     unit_value = parseFloat((row.unit_price / (1 + percentage_igv / 100)) / 1.10)
    // }
    if (row.affectation_igv_type_id === '10') {
        unit_value = row.unit_price / (1 + percentage_igv / 100)
    }

    row.unit_value = unit_value
    
    let total_value_partial = unit_value * row.quantity

    /* Discounts */
    let discount_base = 0
    let discount_no_base = 0
    if (row.discount) {
        let discount = row.discount
        if (discount.percentage > 0) {       
            if (discount.is_amount) {
                discount.base = total_value_partial          
                discount.amount = discount.percentage / 1.10
                
                let percentage =  100 * (parseFloat(discount.amount) / parseFloat(discount.base))
                discount.factor = percentage / 100
                if (discount.discount_type.base) {
                    discount_base += discount.amount
                } else {
                    discount_no_base += discount.amount
                }
            } else {
                let percentage = parseFloat(discount.percentage)
                discount.factor = percentage / 100
                discount.base = total_value_partial
                discount.amount = discount.base * discount.factor
                if (discount.discount_type.base) { 
                    discount_base += discount.amount
                } else {
                    discount_no_base += discount.amount
                }
            }
        }
    }

    // console.log('total base discount:'+discount_base)
    // console.log('total no base discount:'+discount_no_base)

    /* Charges */
    let charge_base = 0
    let charge_no_base = 0
    // row.charges.forEach((charge, index) => {
    //     charge.percentage = parseFloat(charge.percentage)
    //     charge.factor = charge.percentage / 100
    //     charge.base = round(total_value_partial, 2)
    //     charge.amount = round(charge.base * charge.factor, 2)
    //     if (charge.charge_type.base) {
    //         charge_base += charge.amount
    //     } else {
    //         charge_no_base += charge.amount
    //     }
    //     row.charges.splice(index, charge)
    // })
    // console.log('total base charge:'+charge_base)
    // console.log('total no base charge:'+charge_no_base)

    let total_isc = 0
    let total_other_taxes = 0

    let total_discount = discount_base + discount_no_base
    let total_charge = charge_base + charge_no_base
    let total_value = total_value_partial - total_discount + total_charge
    let total_base_igv = total_value_partial - discount_base + total_isc

    let total_igv = 0

    if (row.affectation_igv_type_id === '10') {
        total_igv = total_base_igv * percentage_igv / 100
    }
    if (row.affectation_igv_type_id === '20') { //Exonerated
        total_igv = 0
    }
    if (row.affectation_igv_type_id === '30') { //Unaffected
        total_igv = 0
    }

    let total_taxes = total_igv + total_isc + total_other_taxes
    let total = total_value + total_taxes

    row.total_charge = total_charge
    row.total_discount = total_discount
    // row.total_charge = total_charge
    row.total_value = total_value
    row.total_base_igv = total_base_igv
    row.total_igv =  total_igv
    row.total_taxes = total_taxes
    row.total = round(total, 2)

    // if (row.affectation_igv_type.free) {
    //     row.price_type_id = '02'
    //     row.unit_price = parseFloat(total_value_partial * 1.10)
    //     row.total_value = parseFloat(total_value_partial - total_discount + total_charge)
    //     row.total_igv = parseFloat(row.total_value * 0.10)
    //     row.total_taxes = parseFloat(row.total_igv + total_isc + total_other_taxes)
    //     row.total = parseFloat(row.total_value + row.total_igv)
    // } else {
    //     row.price_type_id = '01'
    // }

    //impuesto bolsa
    if (row_old.has_plastic_bag_taxes) {
        row.total_plastic_bag_taxes = round(row.quantity * row.item.amount_plastic_bag_taxes, 1)
    }
    
    return row
}

export {calculateRowItem}